import { ReactComponent as Chat } from '../../Assets/message-solid.svg';
import { ReactComponent as SendLogo } from '../../Assets/paper-plane-top-solid.svg';
import ConversationItem from "../../Components/ConversationItem/ConversationItem";
import FormLoading from '../../Components/FormLoading/FormLoading';
import { useConfigStore } from "../../Stores/Config";
import { useTicketStore } from "../../Stores/Ticket";
import "./Conversations.scss";

function Conversations() {
    const previousFeedbackItems = useTicketStore(state => state.previousTickets);
    const configStore = useConfigStore();
    const ticketStore = useTicketStore();

    const handleScroll = (e: any) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom && previousFeedbackItems.length > 0) {
            ticketStore.loadMoreConversations();
        }
    }

    const renderFeedbackItems = () => {
        if (!previousFeedbackItems || previousFeedbackItems.length === 0) {
            return <div className="conversations-no-conversations">
                <Chat className="no-messages-yet" />
                <span className="title">{configStore.config.allConversationsMenuLabel}</span>
                <span className="description">{configStore.translateText("noMessages")}</span>
                <div className="action-button form-send-button" onClick={() => {
                    ticketStore.createNewConversation({});
                }}>
                    {configStore.translateText("sendMessage")}
                    <SendLogo />
                </div>
            </div>
        }

        return (
            <>
                <div>
                    {previousFeedbackItems.map((ticket, index) => {
                        return <ConversationItem ticket={ticket} index={index} key={ticket.id} />
                    })}
                    <div className="conversations-load-more">
                        {ticketStore?.loadingTickets &&
                            <FormLoading />
                        }
                    </div>
                </div>
                <div className="action-overlay">
                    <div className="action-button form-send-button" onClick={() => {
                        ticketStore.createNewConversation({});
                    }}>
                        {configStore.translateText("sendMessage")}
                        <SendLogo />
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className="conversations" onScroll={handleScroll}>
            {renderFeedbackItems()}
        </div>
    );
}

export default Conversations;
