import { useState } from "react";
import { useEffect, useRef } from "react";
import FormLoading from "../../Components/FormLoading/FormLoading";
import { useAppViewStore } from "../../Stores/AppView";
import { useConfigStore } from "../../Stores/Config";
import { useRouterStore } from "../../Stores/Router";
import { ReactComponent as PlusIcon } from '../../Assets/plus-solid.svg';
import "./AppView.scss";

function AppView() {
    const appViewStore = useAppViewStore();
    const routerStore = useRouterStore();
    const configStore = useConfigStore();
    const iFrameRef = useRef(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (appViewStore.currentApp?.url) {
            setLoading(true);
        }
    }, [appViewStore.currentApp?.url]);

    useEffect(() => {
        if (iFrameRef && iFrameRef.current) {
            appViewStore.setAppFrame(iFrameRef.current);
        }
    }, [iFrameRef]);

    if (!appViewStore.currentApp) {
        return null;
    }

    return (
        <div className={`app-view ${loading && 'app-view--loading'}`}>
            {loading && <FormLoading />}
            <iframe
                allowFullScreen
                src={appViewStore.currentApp.url}
                frameBorder="0"
                allow="autoplay; encrypted-media; fullscreen;"
                ref={iFrameRef}
                onLoad={() => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 800);
                }} />
            {appViewStore.pageStack.length === 1 && appViewStore.currentApp?.name === "Roadmap" && appViewStore.appData.tab !== "roadmap" &&
                <div className="action-overlay">
                    <div className="action-button form-send-button" onClick={() => {
                        routerStore.setPage("flow", {
                            flowId: "featurerequests",
                        });
                    }}>
                        <PlusIcon />
                        {configStore.translateText("requestFeature")}
                    </div>
                </div>
            }
        </div>
    );
}

export default AppView;
