import create from "zustand";
import produce from "immer";
import CommunicationManager from "../Helper/CommunicationManager";
import { useSessionStore } from "./Session";
import { saveToGleapCache } from "../Helper/CacheReader";
import Communicator from "../Helper/Communicator";
import { validateRecaptchaAction } from "../Helper/Recaptcha";
import { useConfigStore } from "./Config";

export const validateEmail = (email: string) => {
  const matches = String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  if (matches && matches.length > 0) {
    return true;
  }
  return false;
};

interface FormState {
  isValid: boolean;
  areStepsValid: any;
  action: any;
  preFillData: any;
  formData: any;
  sendingForm: boolean;
  checkingDuplicates: boolean;
  formSent: boolean;
  formError?: string;
  currentStep: number;
  duplicates: any[];
  formAnswers: any[];
  formAnswersQueue: any[];
  formAnswersQueueProcessing: boolean;
  formAnswersLastType: string;
  feedbackFlow?: string;
  isSurveyFlow?: boolean;
  hideBackButton?: boolean;
  navigationDirection: string;
  setPreFillFormData: (data: any) => void;
  setFormData: (formKey: string, data: any, autoNext?: boolean) => void;
  setFormAction: (action: any) => void;
  getCurrentFormItems: () => any[];
  checkDuplicateFeatureRequest: () => void;
  getFormItemsForPage: (page: number) => any[];
  validateForm: () => void;
  resetForm: () => void;
  sendForm: (forceSubmit?: boolean) => void;
  pushFormAnswerStep: (formAnswerStep: any) => void;
  workFormAnswerQueue: () => void;
  isSurvey: () => boolean;
  hasOnlyOnePage: () => boolean;
  showNextStep: () => void;
  showPrevStep: () => void;
  setFormSent: () => void;
  currentElementIsAlone: () => boolean;
  currentPageCanAutoNext: () => boolean;
  isCurrentStepValid: () => boolean;
  nextAction: () => void;
  clearFeedbackFlowOptions: () => void;
  canAlreadySubmitForm: () => boolean;
  getProgress: () => number;
  pushFormQuestion: (currentStep: any) => void;
}

const typeCanAutoNext = (type: string) => {
  switch (type) {
    case "text":
      return true;
    case "textarea":
      return false;
    case "capture":
      return false;
    case "netpromoterscore":
      return true;
    case "onetofive":
      return true;
    case "rating":
      return true;
    case "multiplechoice":
      return true;
    case "upload":
      return false;
    case "privacypolicy":
      return false;
    default:
      return false;
  }
};

const isFieldValid = (field: any, fieldData: any) => {
  if (!field.required) {
    return true;
  }

  if (
    !field ||
    fieldData === null ||
    fieldData === undefined ||
    fieldData.value === undefined ||
    fieldData.value === null
  ) {
    return false;
  }

  const fieldValue = fieldData.value;

  // Check text field
  if (field.type === "text" && fieldValue.length > 0) {
    if (field.inputtype === "email") {
      return validateEmail(fieldValue);
    }
    return true;
  }

  if (field.type === "textarea" && fieldValue.length > 0) {
    return true;
  }

  if (field.type === "multiplechoice" && fieldValue.length > 0) {
    return true;
  }

  if (
    field.type === "netpromoterscore" &&
    !(fieldValue === null || fieldValue === undefined)
  ) {
    return true;
  }

  if (field.type === "rating" && (fieldValue > 0 || fieldValue.length > 0)) {
    return true;
  }

  if (field.type === "upload" && fieldValue.length > 0) {
    return true;
  }

  if (field.type === "onetofive" && (fieldValue > 0 || fieldValue.length > 0)) {
    return true;
  }

  if (field.type === "privacypolicy" && fieldValue.length > 0) {
    return true;
  }

  return false;
};

export const useFormStore = create<FormState>()((set, get) => ({
  isValid: false,
  areStepsValid: {},
  sendingForm: false,
  checkingDuplicates: false,
  duplicates: [],
  formSent: false,
  formError: undefined,
  isSurveyFlow: false,
  feedbackFlow: undefined,
  hideBackButton: false,
  action: undefined,
  navigationDirection: "forwards",
  formData: {},
  preFillData: {},
  formAnswers: [],
  formAnswersQueue: [],
  currentStep: 0,
  formAnswersQueueProcessing: false,
  formAnswersLastType: "",
  clearFeedbackFlowOptions: () => {
    set({
      isSurveyFlow: false,
      feedbackFlow: undefined,
      hideBackButton: false,
    });
  },
  isSurvey: () => {
    return get().isSurveyFlow ?? false;
  },
  isCurrentStepValid: () => {
    const { areStepsValid, currentStep } = get();
    if (areStepsValid && areStepsValid[currentStep] === true) {
      return true;
    }
    return false;
  },
  pushFormAnswerStep: (formAnswerStep: any) => {
    const formStore = get();
    set({
      formAnswersQueue: [...formStore.formAnswersQueue, formAnswerStep],
    });
    formStore.workFormAnswerQueue();
  },
  workFormAnswerQueue: () => {
    const formStore = get();

    if (formStore.formAnswersQueueProcessing) {
      return;
    }

    if (formStore.formAnswersQueue.length > 0) {
      const formAnswerStep = formStore.formAnswersQueue[0];
      set({
        formAnswersLastType: "",
        formAnswersQueueProcessing: true,
        formAnswers: [...formStore.formAnswers, formAnswerStep],
        formAnswersQueue: formStore.formAnswersQueue.slice(1),
      });

      formStore.formAnswers.push(formAnswerStep);

      const delay = formAnswerStep.delay ?? 0;
      setTimeout(() => {
        formStore.formAnswersQueueProcessing = false;

        set({
          formAnswersLastType: formAnswerStep.type,
          formAnswersQueueProcessing: false,
        });
        formStore.workFormAnswerQueue();
      }, delay);
    }
  },
  nextAction: () => {
    const formStore = get();

    const pushCustomerReply = () => {
      const currentPageItems = JSON.parse(
        JSON.stringify(get().getFormItemsForPage(get().currentStep))
      );
      for (var i = 0; i < currentPageItems.length; i++) {
        const field = currentPageItems[i];
        const data = formStore.formData[field.name];

        // Skip hidden fields.
        if (field.hidden || (field.defaultValue && field.hideOnDefaultSet)) {
          continue;
        }

        // Skip upload and capture fields.
        if (
          field.type === "upload" ||
          field.type === "capture" ||
          field.type === "privacypolicy"
        ) {
          continue;
        }

        var textData = data && data.value !== null && data.value !== undefined ? `${data.value}` : "--";

        // Fallback for choices.
        if (field.type === "multiplechoice" && field.choices && data && data.value) {
          let choice = field.choices.find((choice: any) => choice && choice.id === data.value);
          if (choice) {
            textData = choice.label ?? "--";
          }
        }

        // Push current step.
        formStore.pushFormAnswerStep({
          step: formStore.currentStep,
          type: "answer",
          delay: 0,
          data: textData,
        });
      }
    };

    if (formStore.canAlreadySubmitForm()) {
      if (formStore.isValid) {
        pushCustomerReply();
        formStore.sendForm();
      }
    } else {
      if (formStore.isCurrentStepValid()) {
        pushCustomerReply();
        formStore.showNextStep();
      }
    }
  },
  hasOnlyOnePage: () => {
    if (!get().action.pages || get().action.pages <= 1) {
      return true;
    }

    return false;
  },
  getProgress: () => {
    if (!get().action.pages) {
      return 0;
    }

    return Math.round(((get().currentStep + 1) / get().action.pages) * 100);
  },
  canAlreadySubmitForm: () => {
    const formStore = get();
    return formStore.currentStep >= (formStore.action?.pages ?? 0) - 1;
  },
  currentElementIsAlone: () => {
    const currentPageItems = JSON.parse(
      JSON.stringify(get().getFormItemsForPage(get().currentStep))
    );

    var pages = 0;
    for (var i = 0; i < currentPageItems.length; i++) {
      const field = currentPageItems[i];
      if (
        field.hideOnDefaultSet &&
        field.defaultValue &&
        field.defaultValue.length > 0
      ) {
      } else {
        pages++;
      }
    }

    // Disale auto next if there are more active items on the page.
    if (pages > 1) {
      return false;
    }
    return true;
  },
  currentPageCanAutoNext: () => {
    var canAutoNext = true;
    const currentPageItems = JSON.parse(
      JSON.stringify(get().getFormItemsForPage(get().currentStep))
    );
    for (let i = 0; i < currentPageItems.length; i++) {
      const field = currentPageItems[i];
      if (
        field.hideOnDefaultSet &&
        field.defaultValue &&
        field.defaultValue.length > 0
      ) {
        currentPageItems.splice(i, 1);
        i--;
      }
    }

    for (let i = 0; i < currentPageItems.length; i++) {
      const field = currentPageItems[i];
      if (!typeCanAutoNext(field.type)) {
        canAutoNext = false;
      }
    }

    // Disale auto next if there are more active items on the page.
    if (currentPageItems.length > 1) {
      canAutoNext = false;
    }
    return canAutoNext;
  },
  getFormItemsForPage: (page: number) => {
    const { action } = get();
    const currentForm = action.form;
    if (!currentForm) {
      return [];
    }
    return currentForm.filter((item: any) => {
      if (item && item.page === page) {
        return true;
      }
      return false;
    });
  },
  getCurrentFormItems: () => {
    const { currentStep } = get();
    return get().getFormItemsForPage(currentStep);
  },
  checkDuplicateFeatureRequest: () => {
    const sessionStore = useSessionStore.getState();

    set({
      checkingDuplicates: true,
    });

    var xhr = new XMLHttpRequest();
    xhr.open("POST", sessionStore.apiUrl + "/bugs/checkduplicatefeaturerequest");
    if (sessionStore.session) {
      xhr.setRequestHeader("Api-Token", sessionStore.sdkKey);
      xhr.setRequestHeader("Gleap-Id", sessionStore.session.gleapId ?? "");
      xhr.setRequestHeader(
        "Gleap-Hash",
        sessionStore.session.gleapHash ?? ""
      );
    }
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200 && xhr.responseText) {
        try {
          const data = JSON.parse(xhr.responseText);
          if (data && data.length > 0) {
            set({
              duplicates: data,
            });
          } else {
            // Submit form as we couldn't find any duplicates.
            get().sendForm(true);
          }
        } catch (exp) { }

        set({
          checkingDuplicates: false,
        });
      }
    };

    xhr.send(JSON.stringify({
      title: get().formData?.title?.value ?? "",
      description: get().formData?.description?.value ?? "",
    }));
  },
  sendForm: (forceSubmit = false) => {
    const { isValid, formData } = get();
    const configStore = useConfigStore.getState();
    if (!isValid) {
      return;
    }

    // Check for feature request duplicates.
    if (get().action?.feedbackType === "FEATURE_REQUEST" && !forceSubmit && !configStore.config?.showNoFeatureSuggestions) {
      get().checkDuplicateFeatureRequest();

      return;
    }

    set({
      sendingForm: true,
      duplicates: [],
      checkingDuplicates: false,
    });

    return validateRecaptchaAction("submitfeedback")
      .then((token) => {
        // Map formdata to plain formdata object.
        var formDataToSend: any = {};
        var formDataKeys = Object.keys(formData);
        for (let i = 0; i < formDataKeys.length; i++) {
          const key = formDataKeys[i];
          if (key === "capture" || key === "privacypolicy") {
            continue;
          }
          const field = formData[key];
          formDataToSend[key] = field.value;
        }

        // Send to communicator.
        CommunicationManager.getInstance().sendMessage({
          name: "send-feedback",
          data: {
            formData: formDataToSend,
            action: get().action,
            outboundId: get().feedbackFlow,
            spamToken: token,
          },
        });
      })
      .catch(() => {
        set({
          formSent: false,
          sendingForm: false,
          formError: "Something went wrong. Please try again later.",
        });
      });
  },
  showNextStep: () => {
    const { currentStep, pushFormQuestion } = get();
    const newStep = currentStep + 1;
    set({
      navigationDirection: "forwards",
      currentStep: newStep,
    });

    pushFormQuestion(newStep);
  },
  pushFormQuestion: (currentStep: any) => {
    const formStore = get();
    const configStore = useConfigStore.getState();
    const currentPageItems = JSON.parse(
      JSON.stringify(get().getFormItemsForPage(currentStep))
    );

    for (var i = 0; i < currentPageItems.length; i++) {
      const field = currentPageItems[i];

      if (field.hidden || (field.defaultValue && field.hideOnDefaultSet)) {
        continue;
      }

      var question = field.title;
      if (field.description && field.description.length > 0) {
        question += `\n${field.description}`;
      }

      if (question && question.length > 0) {
        // Push current step.
        formStore.pushFormAnswerStep({
          step: currentStep,
          type: "question",
          data: question,
          delay: 1000,
        });

        // Only push first message.
        return;
      }
    }
  },
  showPrevStep: () => {
    const { currentStep } = get();
    set({
      navigationDirection: "backwards",
      currentStep: currentStep - 1,
    });
  },
  resetForm: () => {
    set({
      action: undefined,
      formData: {},
      duplicates: [],
      checkingDuplicates: false,
      formAnswers: [],
      hideBackButton: false,
      formAnswersQueue: [],
      currentStep: 0,
      isValid: false,
      areStepsValid: {},
      sendingForm: false,
      formSent: false,
      formError: undefined,
      formAnswersQueueProcessing: false,
      formAnswersLastType: "",
      navigationDirection: "forwards",
    });
  },
  setPreFillFormData: (data: any) => {
    set({
      preFillData: data,
    });
  },
  setFormData: (formKey: string, data: any, autoNext = false) => {
    const formItemConfig = get().action.form.find(
      (configItem: any) => configItem.name === formKey
    );
    if (!formItemConfig) {
      return;
    }

    if (formItemConfig.remember) {
      saveToGleapCache(
        `formitem-${useSessionStore.getState().sdkKey}-${formItemConfig.name}`,
        data
      );
    }

    set({
      formData: produce(get().formData, (draft: any) => {
        const newFieldData = {
          ...draft[formKey],
          ...data,
        };
        draft[formKey] = {
          ...newFieldData,
          valid: isFieldValid(formItemConfig, newFieldData),
        };
      }),
    });

    get().validateForm();

    if (autoNext && get().currentPageCanAutoNext()) {
      setTimeout(() => {
        get().nextAction();
      }, 300);
    }
  },
  validateForm: () => {
    const formData = get().formData;

    // areStepsValid
    var isFormValid = true;
    var areFormStepsValid: any = {};

    for (let step = 0; step < get().action.pages; step++) {
      let formConfig = get().getFormItemsForPage(step);
      areFormStepsValid[step] = true;
      for (let i = 0; i < formConfig.length; i++) {
        const field = formConfig[i];
        const fieldData = formData[field.name];
        if (!isFieldValid(field, fieldData)) {
          isFormValid = false;
          areFormStepsValid[step] = false;
        }
      }
    }

    set({
      isValid: isFormValid,
      areStepsValid: areFormStepsValid,
    });
  },
  setFormAction: (action: any) => {
    action = JSON.parse(JSON.stringify(action));

    // Notify flow started.
    Communicator.notifyEvent("flow-started", action);

    // Transform form data.
    if (action.form && action.form.length > 0) {
      // Cleanup form from unsupported items.
      let newFormArray = [];
      for (var i = 0; i < action.form.length; i++) {
        var feedbackOption = action.form[i];
        if (
          feedbackOption &&
          feedbackOption.type !== "privacypolicy" &&
          feedbackOption.type !== "spacer" &&
          feedbackOption.type !== "submit" &&
          feedbackOption.name !== "reportedBy"
        ) {
          newFormArray.push(feedbackOption);
        }
      }

      // Update form items.
      action.form = newFormArray;
      action.pages = action.singlePageForm === true ? 1 : newFormArray.length;

      // Add page id's
      for (var i = 0; i < action.form.length; i++) {
        var feedbackOption = action.form[i];
        if (action.singlePageForm === true) {
          feedbackOption.page = 0;
        } else {
          feedbackOption.page = i;
        }
      }

      // Inject email collection.
      if (action.collectEmail === true || action.collectEmail === undefined) {
        var defaultValue = undefined;
        const session = useSessionStore.getState().session;
        if (
          session &&
          session.userId &&
          session.userId.length > 0 &&
          session.email &&
          session.email.length > 0 &&
          validateEmail(session.email)
        ) {
          defaultValue = session.email;
        }

        action.form.push({
          title: "Email",
          placeholder: "Your e-mail",
          type: "text",
          inputtype: "email",
          name: "reportedBy",
          required: true,
          remember: true,
          defaultValue,
          hideOnDefaultSet: true,
          page: action.form[action.form.length - 1].page,
        });
      }

      // Inject privacy policy.
      if (!action.disableUserScreenshot || action.enableUserScreenRecording) {
        var captureItem = {
          name: "capture",
          type: "capture",
          enableScreenshot: !action.disableUserScreenshot ? true : false,
          autostartDrawing: true,
          enableCapture: action.enableUserScreenRecording ? true : false,
          captureTitle: action.captureTitle
            ? action.captureTitle
            : "Record screen",
          screenshotTitle: action.screenshotTitle
            ? action.screenshotTitle
            : "Mark the bug",
          page: action.form[action.form.length - 1].page,
        };
        action.form.push(captureItem);
      }

      // Inject privacy policy.
      if (action.privacyPolicyEnabled) {
        var policyItem = {
          name: "privacypolicy",
          type: "privacypolicy",
          required: true,
          url: action.privacyPolicyUrl,
          page: action.form[action.form.length - 1].page,
        };
        action.form.push(policyItem);
      }

      // Check if we have default values available.
      for (var i = 0; i < action.form.length; i++) {
        var feedbackOption = action.form[i];
        const value = get().preFillData[feedbackOption.name];
        if (value !== undefined) {
          feedbackOption.defaultValue = value;
        }
      }
    }

    set({
      action,
    });

    get().pushFormQuestion(0);
  },
  setFormSent: () => {
    set({
      formSent: true,
      sendingForm: false,
    });
  },
}));
