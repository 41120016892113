import { useEffect, useRef, useState } from "react";
import { ReactComponent as SendLogo } from "../../Assets/paper-plane-top-solid.svg";
import { useConfigStore } from "../../Stores/Config";
import "./AnswerBotChatMessageComposer.scss";

export const defaultAttachmentMessage = "📎 Attachment";

function AnswerBotChatMessageComposer({
  sendMessage,
  onHeightUpdated,
}: {
  sendMessage: (message: string) => void;
  onHeightUpdated: (height: number) => void;
}) {
  const config = useConfigStore();
  const textareaRef = useRef(null as any);
  const [currentValue, setCurrentValue] = useState("");

  useEffect(() => {
    if (textareaRef && textareaRef.current) {
      textareaRef.current.style.height = "0px";
      var scrollHeight = textareaRef.current.scrollHeight;
      if (scrollHeight < 57) {
        scrollHeight = 57;
      }

      textareaRef.current.style.height = scrollHeight + "px";
      onHeightUpdated(scrollHeight);
    }
  }, [currentValue]);

  const internalSendMessage = () => {
    if (!(currentValue && currentValue.length > 0)) {
      return;
    }

    sendMessage((currentValue && currentValue.length > 0) ? currentValue : defaultAttachmentMessage);
    setCurrentValue("");
  };

  const onEnterPress = (e: any) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      internalSendMessage();
    }
  };

  return (
    <div className="chat-message-composer">
      <textarea
        value={currentValue}
        placeholder={config.translateText("writeReply")}
        ref={textareaRef}
        onChange={(e) => {
          setCurrentValue(e.target.value);
        }}
        onKeyDown={onEnterPress}
      />
      <div className="composer-buttons">
        <SendLogo
          onClick={() => {
            internalSendMessage();
          }}
          className={`send-button ${!(currentValue && currentValue.length > 0) &&
            `send-button--disabled`
            } `}
        />
      </div>
    </div>
  );
}

export default AnswerBotChatMessageComposer;
