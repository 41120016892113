import { useEffect, useRef } from 'react';
import './TabBar.scss';
import { useRouterStore } from '../../Stores/Router';
import { ReactComponent as Announcements } from '../../Assets/megaphone-regular.svg';
import { ReactComponent as AnnouncementsActive } from '../../Assets/megaphone-solid.svg';
import { ReactComponent as Roadmap } from '../../Assets/map-location-regular.svg';
import { ReactComponent as RoadmapActive } from '../../Assets/map-location-solid.svg';
import { ReactComponent as Home } from '../../Assets/house-blank-regular.svg';
import { ReactComponent as HomeActive } from '../../Assets/house-blank-solid.svg';
import { ReactComponent as HelpCenter } from '../../Assets/circle-question.svg';
import { ReactComponent as HelpCenterActive } from '../../Assets/circle-question-solid.svg';
import { ReactComponent as Chat } from '../../Assets/message-regular.svg';
import { ReactComponent as ChatActive } from '../../Assets/message-solid.svg';
import { useFeatureRequestStore } from '../../Stores/FeatureRequests';
import { useConfigStore } from '../../Stores/Config';
import { useTicketStore } from '../../Stores/Ticket';
import { useHelpCenterStore } from '../../Stores/HelpCenter';
import { useAppViewStore } from '../../Stores/AppView';

export const usePrevious = (value: any) => {
    const ref = useRef()
    useEffect(() => { ref.current = value })

    return ref.current as any
}

function TabBar() {
    const routerStore = useRouterStore();
    const featureRequestStore = useFeatureRequestStore();
    const helpCenterStore = useHelpCenterStore();
    const currentPage = routerStore.currentPage;
    const configStore = useConfigStore();
    const ticketStore = useTicketStore();
    const appView = useAppViewStore();

    const unreadTickets = ticketStore.previousTickets.filter((ticket) => {
        if (ticket.sessionNotificationsUnread) {
            return true;
        }
        return false;
    });

    const renderNewsTab = () => {
        if (configStore.config.hideNews) {
            return null;
        }

        const active = (currentPage === "news" || currentPage === "newsdetails");

        return (
            <div className={`tab-bar-item ${active && 'active'}`} onClick={() => routerStore.setPage("news")}>
                {active ? <AnnouncementsActive /> : <Announcements />}
                <span>{configStore.config.newsMenuLabel}</span>
            </div>
        );
    }

    const renderRoadmapTab = () => {
        if (configStore.config.hideFeatureRequests) {
            return null;
        }

        const active = currentPage === "appview" && appView.currentApp?.name === "Roadmap";

        return (
            <div className={`tab-bar-item ${active && 'active'}`} onClick={() => {
                featureRequestStore.openFeatureRequest();
            }}>
                {active ? <RoadmapActive /> : <Roadmap />}
                <span>{configStore.config.featureRequestsMenuLabel}</span>
            </div>
        );
    }

    const renderConversations = () => {
        if (configStore.config.hideAllConversations) {
            return null;
        }

        const active = (currentPage === "conversations" || currentPage === "conversation");

        return (
            <div className={`tab-bar-item ${active && 'active'}`} onClick={() => routerStore.setPage("conversations")}>
                <div className='icon-container'>
                    {active ? <ChatActive /> : <Chat />}
                    {unreadTickets && unreadTickets.length > 0 && <div className="tab-bar-item--unread">{unreadTickets.length > 9 ? '9+' : unreadTickets.length}</div>}
                </div>
                <span>{configStore.config.allConversationsMenuLabel}</span>
            </div>
        );
    }

    const renderHelpCenter = () => {
        if (configStore.config.hideKnowledgeBase) {
            return null;
        }

        const active = currentPage === "appview" && appView.currentApp?.name === "Help";

        return (
            <div className={`tab-bar-item ${active && 'active'}`} onClick={() => {
                helpCenterStore.openHelpCenter();
            }}>
                <div className='icon-container'>

                    {active ? <HelpCenterActive /> : <HelpCenter />}
                </div>
                <span>{configStore.config.knowledgeBaseMenuLabel}</span>
            </div>
        );
    }

    if (configStore.config.hideNews && configStore.config.hideFeatureRequests && configStore.config.hideAllConversations && configStore.config.hideKnowledgeBase) {
        return null;
    }
    
    if (routerStore.hideBackButton) {
        return null;
    }

    return (<>
        <div className="tab-bar">
            <div className={`tab-bar-item ${currentPage === "menu" && 'active'}`} onClick={() => routerStore.setPage("menu")}>
                {currentPage === "menu" ? <HomeActive /> : <Home />}
                <span>{configStore.translateText("homeLabel")}</span>
            </div>
            {renderConversations()}
            {renderNewsTab()}
            {renderRoadmapTab()}
            {renderHelpCenter()}
        </div>
    </>);
}

export default TabBar;
