import { useState } from 'react';
import Communicator from '../../Helper/Communicator';
import { ConfigMenuItem } from '../../Stores/Config';
import { useRouterStore } from '../../Stores/Router';
import { useTicketStore } from '../../Stores/Ticket';
import "./WidgetMenuButton.scss";

function WidgetMenuButton({ menuItem, index }: {
    menuItem: ConfigMenuItem;
    index: number;
}) {
    const [isLoading, setIsLoading] = useState(false);
    const routerStore = useRouterStore();
    const ticketStore = useTicketStore();

    return (
        <div className="widget-menu-button" onClick={() => {
            if (isLoading) {
                return;
            }

            // Make component loading
            setIsLoading(true);

            if (menuItem.actionType === "CUSTOM_ACTION") {
                if (menuItem.actionBody) {
                    Communicator.runCustomAction(menuItem.actionBody);
                    Communicator.closeWidget();
                }
            } else if (menuItem.actionType === "REDIRECT_URL") {
                if (menuItem.actionBody) {
                    Communicator.openURL(menuItem.actionBody, menuItem.actionOpenInNewTab ? true : false);
                    Communicator.closeWidget();
                }
            } else if (menuItem.actionType === "BOT") {
                ticketStore.createNewConversation({
                    botId: menuItem.botId,
                });
            } else {
                routerStore.setPage("flow", {
                    flowId: menuItem.actionType,
                });
            }
        }}>
            <div className='widget-menu-button-labels'>
                <div className='widget-menu-button-title'>{menuItem.title}</div>
                <div className='widget-menu-button-description'>{menuItem.description}</div>
            </div>
            <div className="widget-menu-button-icon">
                <img src={menuItem.icon} alt={menuItem.title} />
            </div>
        </div>
    );
}

export default WidgetMenuButton;
