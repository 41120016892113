import { useConfigStore } from '../../Stores/Config';
import { useFormStore } from '../../Stores/Form';
import CommunicationManager from '../../Helper/CommunicationManager';
import { ReactComponent as Camera } from '../../Assets/Camera.svg';
import { ReactComponent as Screenshot } from '../../Assets/Screenshot.svg';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import "./CaptureFormItem.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function CaptureFormItem({ formItem }: { formItem: any }) {
    const configStore = useConfigStore();
    const formStore = useFormStore();
    const formData = formStore.formData;
    const fieldFormData = formData[formItem.name];
    const videoCaptureAvailable = typeof navigator.mediaDevices !== "undefined" && typeof navigator.mediaDevices.getDisplayMedia !== "undefined";

    const showMarkerEditor = (type: string) => {
        CommunicationManager.getInstance().sendMessage({
            name: "start-screen-drawing",
            data: type
        });
    }

    if (fieldFormData && fieldFormData.value) {
        return (<div className='capture-buttons'>
            <div className='capture-added-button'>
                {fieldFormData.value === "screenshot" ? <Screenshot /> : <Camera />}
                <span>{configStore.translateText(fieldFormData.value === "screenshot" ? "screenshot" : "screenRecording")}</span>
                <div className='capture-added-button-remove' onClick={() => {
                    formStore.setFormData("capture", {
                        value: undefined,
                        dirty: false
                    });
                    CommunicationManager.getInstance().sendMessage({
                        name: "cleanup-drawings"
                    });
                }}>
                    <FontAwesomeIcon icon={faXmark} /></div>
            </div>
        </div>);
    }

    return (
        <div className='capture-buttons'>
            {formItem.enableScreenshot &&
                <div className='capture-button capture-button--screenshot tooltip' onClick={() => {
                    showMarkerEditor("screenshot");
                }}>
                    <Screenshot />
                    <span>{formItem.screenshotTitle}</span>
                    <div className="top">
                        <p>{formItem.screenshotTitle}</p>
                        <i></i>
                    </div>
                </div>}
            {formItem.enableCapture && videoCaptureAvailable &&
                <div className='capture-button tooltip' onClick={() => {
                    showMarkerEditor("capture");
                }}>
                    <Camera />
                    <span>{formItem.captureTitle}</span>
                    <div className="top">
                        <p>{formItem.captureTitle}</p>
                        <i></i>
                    </div>
                </div>}
        </div>
    );
}

export default CaptureFormItem;
