import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import Heading from "@tiptap/extension-heading";
import Image from "@tiptap/extension-image";
import Mention from "@tiptap/extension-mention";
import Placeholder from "@tiptap/extension-placeholder";
import TextStyle from "@tiptap/extension-text-style";
import Youtube from "@tiptap/extension-youtube";
import { generateHTML } from "@tiptap/html";
import StarterKit from "@tiptap/starter-kit";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import IconButton from "../../Components/IconButton/IconButton";
import CustomLink from "../../Helper/CustomLink";
import { HelpCenterArticleExtension } from "../../Helper/HelpcenterExtension";
import IFrame from "../../Helper/IFrame";
import { replaceAll } from "../../Helper/String";
import { useNewsStore } from "../../Stores/News";
import { useRouterStore } from "../../Stores/Router";
import "./NewsDetails.scss";

function NewsDetails() {
  const newsStore = useNewsStore();
  const routerStore = useRouterStore();
  const currentArticle = newsStore.currentArticle;
  const [showShadow, setShowShadow] = useState(false);
  const [showTitle, setShowTitle] = useState(false);
  const headerTitleRef = useRef(null as any);
  const headerImageRef = useRef(null as any);

  useEffect(() => {
    if (routerStore.currentData && routerStore.currentData.id) {
      newsStore.loadArticle(routerStore.currentData.id);
    }
  }, [routerStore.currentData]);

  const renderContent = () => {
    var content = "";
    try {
      if (currentArticle) {
        content = generateHTML(currentArticle.message, [
          StarterKit.configure({ codeBlock: {}, heading: false }),
          Heading.configure({
            levels: [1, 2, 3],
          }),
          Mention,
          Placeholder,
          CustomLink,
          Image,
          HelpCenterArticleExtension,
          TextStyle,
          Youtube.configure({ controls: true }),
          IFrame,
        ]);
        content = replaceAll(content, "\n", "<br>");
      }
    } catch (_) { }

    return (
      <>
        <div className={`news-header ${showShadow && 'news-header--shadow'} ${showTitle && 'news-header--title'}`}>
          <span>{currentArticle?.subject}</span>
        </div>
        <div className="news-header--placeholder" />
        {currentArticle?.coverImageUrl ? (
          <img
            ref={headerImageRef}
            className="news-content-image"
            src={currentArticle?.coverImageUrl}
          />
        ) : (
          <div ref={headerImageRef} className="news-content-image" />
        )}
        {(currentArticle) ? (
          <>
            <div className="news-title" ref={headerTitleRef}>{currentArticle?.subject}</div>
            <div className="news-content">
              {currentArticle.sender && (
                <div className="news-content-meta">
                  <img src={currentArticle.sender.profileImageUrl} />
                  <span>
                    {currentArticle.sender.firstName} •{" "}
                    {currentArticle?.sentAt
                      ? moment(currentArticle?.sentAt).fromNow()
                      : moment(currentArticle?.createdAt).fromNow()}
                  </span>
                </div>
              )}
              <div
                className="news-content-body"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
          </>
        ) : (
          <>
            <div className="news-title">
              <div
                className="skeleton-box"
                style={{
                  minWidth: "80%",
                  minHeight: "18px",
                }}
              />
            </div>
            <div className="news-content-skeleton">
              <div
                className="skeleton-box"
                style={{
                  minWidth: "68%",
                  minHeight: "16px",
                  marginBottom: "7px",
                }}
              />
              <div
                className="skeleton-box"
                style={{
                  minWidth: "70%",
                  minHeight: "16px",
                  marginBottom: "7px",
                }}
              />
              <div
                className="skeleton-box"
                style={{
                  minWidth: "65%",
                  minHeight: "16px",
                  marginBottom: "7px",
                }}
              />
            </div>
          </>
        )}
      </>
    );
  };

  const handleScroll = (event: any) => {
    const scrollTop = event.currentTarget.scrollTop;

    var offset = 0;

    if (headerImageRef.current) {
      offset += headerImageRef.current.clientHeight;
    }
    if (headerTitleRef.current) {
      offset += headerTitleRef.current.clientHeight;
    }

    if (scrollTop >= 10) {
      setShowShadow(true);
    } else {
      setShowShadow(false);
    }

    if (scrollTop >= (offset - 30)) {
      setShowTitle(true);
    } else {
      setShowTitle(false);
    }
  };

  return (
    <div className="news-details" onScroll={handleScroll}>
      <div className="back-button">
        <IconButton
          icon={faChevronLeft}
          onClick={() => {
            routerStore.setPage("news");
          }}
        />
      </div>
      {renderContent()}
    </div>
  );
}

export default NewsDetails;
