import { replaceAll } from "./String";

export const contentWithVariables = (content: string, variables: any) => {
    if (!content) {
        return "";
    }

    var innerContent = content;
    let keys = Object.keys(variables);
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        innerContent = replaceAll(innerContent, `{{${key}}}`, variables[key]);
    }

    return innerContent;
};

function escapeHtml(unsafe: string) {
    return unsafe
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
}

export const contentEnhanceCode = (content: string) => {
    if (!content) {
        return "";
    }

    content = content.replace(/```(\w*)?\n?([^`]+)```/g, function (match, p1, p2) {
        return `<pre><code class="${p1 || ''}">${escapeHtml(p2.trim())}</code></pre>`;
    });

    content = content.replace(/`([^`]+)`/g, function (match, p1) {
        return `<code>${escapeHtml(p1)}</code>`;
    });

    return content;
};