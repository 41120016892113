import React from 'react';
import Communicator from '../../Helper/Communicator';
import { useConfigStore } from '../../Stores/Config';
import { useFormStore } from '../../Stores/Form';
import "./CheckboxFormItem.scss";

function CheckboxFormItem({ formItem }: { formItem: any }) {
    const configStore = useConfigStore();
    const formData = useFormStore(state => state.formData);
    const action = useFormStore(state => state.action);
    const fieldFormData = formData[formItem.name];
    const setFormData = useFormStore(state => state.setFormData);
    let privacyURL = action.privacyPolicyUrl;
    if (!action.privacyPolicyUrl || action.privacyPolicyUrl.length === 0) {
        privacyURL = "https://gleap.io/privacy-policy";
    }

    return (
        <div className="checkbox-form-item">
            <label className="form-control">
                <input
                    type="checkbox"
                    name="checkbox-checked"
                    defaultChecked={fieldFormData && fieldFormData.value === "checked"}
                    onChange={(e) => {
                        var checked = "";
                        if (e.currentTarget.checked) {
                            checked = "checked";
                        }
                        setFormData(formItem.name, {
                            value: checked,
                            dirty: true
                        });
                    }} />
                <span>
                    {configStore.translateText(
                        "ppolicyIRead"
                    )}<span className='privacy-link' onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        Communicator.openURL(privacyURL!);
                    }}>{configStore.translateText(
                        "ppolicy"
                    )}</span>.
                </span>
            </label>
        </div>
    );
}

export default CheckboxFormItem;
