import { useConfigStore } from '../../Stores/Config';
import { useTicketStore } from '../../Stores/Ticket';
import BotActionChatMessage from '../BotActionChatMessage/BotActionChatMessage';
import BotActionInputButtons from '../BotActionInputButtons/BotActionInputButtons';
import BotActionInputDropdown from '../BotActionInputDropdown/BotActionInputDropdown';
import BotActionInputText from '../BotActionInputText/BotActionInputText';
import BotActionRatingInput from '../BotActionRatingInput/BotActionRatingInput';
import ChatMessage from '../ChatMessage/ChatMessage';
import "./BotActionInput.scss";

export const actionIsLastOfGroup = (currentBotAction: any) => {
    if (currentBotAction?.action?.type === "input") {
        return true;
    }
    return false;
}

function BotActionInput() {
    const feedbackItemStore = useTicketStore();
    const configStore = useConfigStore();

    const currentAction = feedbackItemStore.currentBotAction?.action;
    if (!currentAction) {
        return null;
    }

    const renderInputComponentForAction = () => {
        switch (currentAction.inputType) {
            case "text":
                return <BotActionInputText action={currentAction} />;
            case "email":
                return <BotActionInputText action={currentAction} />;
            case "number":
                return <BotActionInputText action={currentAction} />;
            case "dropdown":
                return <BotActionInputDropdown action={currentAction} />;
            default:
                return null;
        }
    }

    const renderContent = () => {
        switch (currentAction.type) {
            case "rateconversation":
                return (<BotActionChatMessage>
                    <BotActionRatingInput action={currentAction} />
                </BotActionChatMessage>);
            case "input":
                return (<BotActionChatMessage>
                    {renderInputComponentForAction()}
                </BotActionChatMessage>);
            case "answerbotflow":
                if (feedbackItemStore.hasAnswerBotReply()) {
                    return (<>
                        <ChatMessage key="answerbot" lastOfGroup={true} chatMessage={{
                            data: {
                                content: currentAction.askForAdditionalHelp ?? '',
                                kai: true,
                            },
                            user: undefined,
                            session: undefined,
                            type: "BOT"
                        }}
                            showTime={true}
                        />
                        {currentAction.disableTalkToHuman ? null : <div className='bot-buttons'>
                            {[currentAction.talkToHuman].map((button: any, index: number) => {
                                return (<button key={index} className='bot-button' onClick={() => {
                                    feedbackItemStore.answerBotAction({
                                        skip: true,
                                        message: button,
                                    })
                                }}>{button}</button>);
                            })}
                        </div>}
                    </>);
                }
                return (<ChatMessage key="answerbot" lastOfGroup={true} chatMessage={{
                    data: {
                        content: currentAction?.question ?? '',
                    },
                    user: undefined,
                    session: undefined,
                    type: "BOT"
                }}
                    showTime={true}
                />);
            case "buttons":
                return (<BotActionInputButtons action={currentAction} />);
            default:
                return null;
        };
    }

    return (<div className='bot-action-container'>
        {renderContent()}
    </div>)
}

export default BotActionInput;