import { useCallback, useEffect, useState } from 'react';
import { ReactComponent as CheckIcon } from '../../Assets/check-solid.svg';
import { useFormStore } from '../../Stores/Form';
import "./MultipleChoiceFormItem.scss";

function shuffleArray(array: any[]) {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
}

function MultipleChoiceFormItem({ formItem }: { formItem: any }) {
    const formData = useFormStore(state => state.formData);
    const fieldFormData = formData[formItem.name];
    const [options, setOption] = useState([] as any[]);
    const setFormData = useFormStore(state => state.setFormData);

    useEffect(() => {
        var options = formItem?.choices;
        if (options) {
            if (formItem?.randomizeChoices) {
                shuffleArray(options);
            }

            setOption(options);
        }
    }, [formItem?.choices]);

    const getIndex = (character: string) => {
        return character.charCodeAt(0) - 97;
    }

    const getCharacter = (index: number) => {
        return String.fromCharCode(97 + index);
    }

    const handleUserKeyPress = useCallback((event: any) => {
        const { key, target } = event;
        if (key && target.tagName !== "INPUT" && target.tagName !== "TEXTAREA") {
            const index = getIndex(key);
            if (index >= 0 && index < formItem.choices.length) {
                setFormData(formItem.name, {
                    value: formItem.choices[index],
                    dirty: true
                }, true);
            }
        }
    }, []);

    useEffect(() => {
        window.addEventListener("keydown", handleUserKeyPress);
        return () => {
            window.removeEventListener("keydown", handleUserKeyPress);
        };
    }, [handleUserKeyPress]);

    const getLabel = (option: any) => {
        if (option && option.label) {
            return option.label;
        }
        if (typeof option === "object") {
            return option[''];
        }
        return option;
    }

    const renderOption = (option: any, index: number) => {
        var val = option.id ? option.id : option;
        return (<div key={index} className={`multiple-choice-form-item-choice ${fieldFormData && fieldFormData.value === val && "multiple-choice-form-item-choice--selected"}`} onClick={() => {
            setFormData(formItem.name, {
                value: val,
                dirty: true
            }, true);
        }}>
            <div className='multiple-choice-form-item-choice-shortcut'>{getCharacter(index)}</div>
            <div className='multiple-choice-form-item-choice-value'>{getLabel(option)}</div>
            <div className='multiple-choice-form-item-choice-check'><CheckIcon /></div>
        </div>);
    };

    return (
        <div className="multiple-choice-form-item">
            {options.map((choice: any, index: number) => (
                renderOption(choice, index)
            ))}
        </div>
    );
}

export default MultipleChoiceFormItem;
