import { faChevronLeft, faXmark } from "@fortawesome/pro-regular-svg-icons";
import moment from "moment-timezone";
import { ReactComponent as GleapDefaultIcon } from "../../Assets/DefaultLogo.svg";
import { ReactComponent as GleapBotIcon } from '../../Assets/GleapBotIcon.svg';
import CommunicationManager from "../../Helper/CommunicationManager";
import { truncate } from "../../Helper/FeedbackItemHelper";
import { isOnline } from "../../Helper/OnlineState";
import { useAppViewStore } from "../../Stores/AppView";
import { useConfigStore } from "../../Stores/Config";
import { useFormStore } from "../../Stores/Form";
import { AppMode, useRouterStore } from "../../Stores/Router";
import { useSessionStore } from "../../Stores/Session";
import { useTicketStore } from "../../Stores/Ticket";
import IconButton from "../IconButton/IconButton";
import "./WidgetHeader.scss";

export const getOperatorInfo = (configStore: any, config: any, ticket: any) => {
  if (ticket) {
    // Processing user.
    if (ticket.processingUser) {
      if (ticket.processingUser?.profileImageUrl) {
        return {
          image: <img src={ticket.processingUser?.profileImageUrl} />,
          name: `${ticket.processingUser?.firstName}`,
        };
      }
    }

    // Latest comment.
    if (ticket.latestComment?.user) {
      if (ticket.latestComment.user?.profileImageUrl) {
        return {
          image: <img src={ticket.latestComment.user?.profileImageUrl} />,
          name: `${ticket.latestComment.user?.firstName}`,
        }
      }
    }

    if (ticket.type === "BOT") {
      const operatorAvatarImage = config.operatorAvatarImage;
      if (operatorAvatarImage && operatorAvatarImage.length > 0) {
        return {
          image: <img src={operatorAvatarImage} />,
          name: configStore.getBotName(),
        }
      }

      // Fallback.
      return {
        image: <GleapBotIcon />,
        name: configStore.getBotName(),
      }
    }

    // Team fallback.
    return {
      image: null,
      name: config?.name ?? "Team",
    }
  }

  return null;
}

export const resetWidget = () => {
  useAppViewStore.getState().reset();
  useRouterStore.getState().setHideBackButton(false);
  useFormStore.getState().clearFeedbackFlowOptions();
  useRouterStore.getState().setPage("menu");
}

function WidgetHeader({
  headerOpacity = 1.0,
  scrolled = false,
}: {
  headerOpacity?: number;
  scrolled?: boolean;
}) {
  const sessionStore = useSessionStore();
  const configStore = useConfigStore();
  const formStore = useFormStore();
  const routerStore = useRouterStore();
  const appViewStore = useAppViewStore();
  const ticketStore = useTicketStore();
  const config = configStore.config;
  const hideBackButton = formStore.hideBackButton;

  const unreadTickets = ticketStore.previousTickets.filter((ticket) => {
    if (ticket.id === ticketStore.currentTicket?.id) {
      return false;
    }
    if (ticket.sessionNotificationsUnread) {
      return true;
    }
    return false;
  });

  const closeWidgetAndReset = () => {
    CommunicationManager.getInstance().sendMessage({
      name: "close-widget",
    });

    resetWidget();
  };

  const renderLogo = () => {
    if (config.hideLogo && config.hideLogo === true) {
      return null;
    }

    if (config.logo && config.logo.length > 0) {
      return (
        <img className="widget-header-main-logo" src={config.logo} alt="Logo" />
      );
    }

    return <GleapDefaultIcon className="widget-header-main-logo" />;
  };

  const name = truncate(sessionStore?.getName() ?? "", 18);

  const renderTeamLogos = () => {
    if (config.hideTeam === true) {
      return null;
    }

    if (!(configStore.team && configStore.team.length > 0)) {
      return (
        <div className="operator-info-team-image">{[0, 1, 2].map((index) => {
          return (<div key={index} />)
        })}</div>
      );
    }

    const team = configStore.team.slice(0, 3);
    return (
      <div className="operator-info-team-image">{team.map((teamUser, index) => {
        return (<img key={index} src={teamUser.profileImageUrl} />)
      })}</div>
    );
  }

  const renderMainHeader = () => {
    const description = config.welcomeText;
    const showUsername =
      typeof config.hideUsersName === "undefined" || !config.hideUsersName;
    return (
      <div className={`widget-header-main ${config.hideTeam && "widget-header-main-hideteam"}`}>
        <div
          style={{
            opacity: headerOpacity,
          }}
        >
          <div className="widget-header-main-logo-row">
            {renderLogo()}
            {renderTeamLogos()}
            <IconButton
              className="widget-header-main-close"
              icon={faXmark}
              onClick={() => {
                closeWidgetAndReset();
              }}
            />
          </div>
          <div className="widget-header-main-title">
            <span>{configStore.translateText("hi")}</span>
            {showUsername && name && name.length > 0 && (
              <span className="widget-header-main-title-name">{name}</span>
            )}
            {!config.hideWavingHandAfterName && (
              <span className="hand">{"👋"}</span>
            )}
          </div>
          {description && description.length > 0 && (
            <div className="widget-header-main-description">{description}</div>
          )}
        </div>
      </div>
    );
  };

  const renderFormSubHeader = () => {
    return (
      <div className="widget-header-sub widget-header-sub-with-center">
        {!hideBackButton && routerStore.appMode === AppMode.WIDGET && (
          <IconButton
            icon={faChevronLeft}
            onClick={() => {
              if (formStore.currentStep > 0) {
                formStore.showPrevStep();
              } else {
                routerStore.setPage("menu");
                CommunicationManager.getInstance().sendMessage({
                  name: "cleanup-drawings",
                });
              }
            }}
          />
        )}
        <div className="widget-header-sub-center">
          <div className="widget-header-sub-title">
            {formStore.action?.title}
          </div>
        </div>
        <IconButton
          icon={faXmark}
          onClick={() => {
            closeWidgetAndReset();
          }}
        />
      </div>
    );
  };

  const renderConversationsSubHeader = () => {
    return (
      <div className="widget-header-sub">
        <div className="widget-header-sub-title widget-header-sub-title--no-back">
          {configStore.config.allConversationsMenuLabel}
        </div>
        <IconButton
          icon={faXmark}
          onClick={() => {
            closeWidgetAndReset();
          }}
        />
      </div>
    );
  };

  const renderConversationSubHeader = () => {
    const operatorInfo = getOperatorInfo(configStore, config, ticketStore?.currentTicket);
    const { online, nextOpeningTime } = isOnline();

    var replyTime = "";

    if (!configStore.config.showOnlineStatus) {
      replyTime = configStore.config.name ?? "";
    } else {
      if (ticketStore.currentTicket) {
        if (ticketStore.currentTicket?.type === "BOT") {
          replyTime = configStore.translateText("botReplyTime");
        } else {
          if (online) {
            replyTime = `${configStore.translateText("weUsuallyReply")} ${configStore.translateText(configStore.config.replyTime)}.`;
          } else {
            replyTime = `${configStore.translateText("weAreBack")} ${moment(nextOpeningTime).fromNow()}.`;
          }
        }
      }
    }

    const renderOperatorImage = () => {
      if (operatorInfo?.image) {
        return (
          <div className="operator-info-image">{operatorInfo?.image}</div>
        );
      }

      if (configStore.team && configStore.team.length > 0) {
        const team = configStore.team.slice(0, 3);
        return (
          <div className="operator-info-team-image" style={{
            marginRight: `${10 + ((team.length - 1) * 22)}px`,
          }}>{team.map((teamUser, index) => {
            return (<img key={index} src={teamUser.profileImageUrl} />)
          })}</div>
        );
      }

      return null;
    }

    const operatorHeader = () => {
      if (!operatorInfo) {
        return (
          <>
            <div className="operator-info-content-image-loading"></div>
            <div className="operator-info-content-loading">
              <div className="operator-info-content-title-loading"></div>
              <div className="operator-info-content-subtitle-loading"></div>
            </div>
          </>
        );
      }

      return (
        <>
          {renderOperatorImage()}
          <div className="operator-info-content">
            <div className="operator-info-content-title">{operatorInfo.name}</div>
            <div className="operator-info-content-subtitle">{replyTime}</div>
          </div>
        </>
      );
    }

    return (
      <div className="widget-header-sub">
        {!hideBackButton && (
          <IconButton
            unreadCount={unreadTickets.length}
            icon={faChevronLeft}
            onClick={() => {
              if (configStore.config.hideAllConversations) {
                routerStore.setPage("menu");
              } else {
                routerStore.setPage("conversations");
              }
            }}
          />
        )}
        <div className="widget-header-operator-info">
          {operatorHeader()}
        </div>
        <IconButton
          icon={faXmark}
          onClick={() => {
            closeWidgetAndReset();
          }}
        />
      </div>
    );
  };

  const renderConversationSubHeaderTicket = () => {
    const operatorInfo = getOperatorInfo(configStore, config, ticketStore?.currentTicket);
    const { online, nextOpeningTime } = isOnline();

    var replyTime = "";

    if (!configStore.config.showOnlineStatus) {
      replyTime = configStore.config.name ?? "";
    } else {
      if (ticketStore.currentTicket) {
        if (online) {
          replyTime = `${configStore.translateText("weUsuallyReply")} ${configStore.translateText(configStore.config.replyTime)}.`;
        } else {
          replyTime = `${configStore.translateText("weAreBack")} ${moment(nextOpeningTime).fromNow()}.`;
        }
      }
    }

    const renderOperatorImage = () => {
      if (operatorInfo?.image) {
        return (
          <div className="operator-info-image">{operatorInfo?.image}</div>
        );
      }

      if (configStore.team && configStore.team.length > 0) {
        const team = configStore.team.slice(0, 3);
        return (
          <div className="operator-info-team-image" style={{
            marginRight: `${10 + ((team.length - 1) * 22)}px`,
          }}>{team.map((teamUser, index) => {
            return (<img key={index} src={teamUser.profileImageUrl} />)
          })}</div>
        );
      }

      return null;
    }

    const operatorHeader = () => {
      if (!operatorInfo) {
        return (
          <></>
        );
      }

      return (
        <>
          {renderOperatorImage()}
          <div className="operator-info-content">
            <div className="operator-info-content-title">{operatorInfo.name}</div>
            <div className="operator-info-content-subtitle">{replyTime}</div>
          </div>
        </>
      );
    }

    const renderStatus = () => {
      if (ticketStore.currentTicket?.type !== "BUG") {
        return;
      }

      const currentStatus = ticketStore.currentTicket?.status;
      const stati = [
        {
          status: "OPEN",
          title: "submitted",
          isActive: true
        },
        {
          status: "IN_PROGRESS",
          title: "inProgress",
          isActive: currentStatus !== "OPEN"
        },
        {
          status: "DONE",
          title: "resolved",
          isActive: currentStatus === "DONE"
        }
      ];

      return (<div className="widget-ticket-status">
        {stati.map((status, index) => {
          return (<div key={index} className={`widget-ticket-status-item ${status.isActive ? "widget-ticket-status-item--active" : ""}`}>
            <div className="widget-ticket-status-item-bar"></div>
            <div className="widget-ticket-status-item-title">{configStore.translateText(status.title)}</div>
          </div>)
        })}
      </div>)
    }

    return (
      <div className="widget-header-sub widget-header-sub-center">
        <div className="widget-header-sub-header">
          {!hideBackButton && (
            <IconButton
              unreadCount={unreadTickets.length}
              icon={faChevronLeft}
              onClick={() => {
                if (configStore.config.hideAllConversations) {
                  routerStore.setPage("menu");
                } else {
                  routerStore.setPage("conversations");
                }
              }}
            />
          )}
          <div className="widget-header-operator-info">
            {operatorHeader()}
          </div>
          <IconButton
            icon={faXmark}
            onClick={() => {
              closeWidgetAndReset();
            }}
          />
        </div>
        <div className="widget-header-sub-content">
          {renderStatus()}
        </div>
      </div>
    );
  };

  const renderNewsSubHeader = () => {
    return (
      <div className="widget-header-sub">
        <div className="widget-header-sub-title widget-header-sub-title--no-back">
          {configStore.config.newsMenuLabel}
        </div>
        <IconButton
          icon={faXmark}
          onClick={() => {
            closeWidgetAndReset();
          }}
        />
      </div>
    );
  };

  const renderAppViewHeader = () => {
    const showBack = !hideBackButton && appViewStore.pageStack.length > 1;
    return (
      <div className="widget-header-sub">
        {showBack && (
          <IconButton
            icon={faChevronLeft}
            onClick={() => {
              appViewStore.popPage();
            }}
          />
        )}
        <div
          className={`widget-header-sub-title ${!showBack && "widget-header-sub-title--no-back"
            }`}
        >
          {configStore.translateText(appViewStore.getCurrentPage())}
        </div>
        {appViewStore.pageStack.length <= 1 ? (
          <IconButton
            icon={faXmark}
            onClick={() => {
              closeWidgetAndReset();
            }}
          />
        ) : (
          <div className="close-spacer"></div>
        )}
      </div>
    );
  };

  const renderAppExtendedViewHeader = () => {
    const showBack = !hideBackButton && appViewStore.pageStack.length > 1;
    return (
      <div className={`widget-header-sub widget-header-sub--extended ${appViewStore.showExtendedViewTitle && 'widget-header-sub--extended--scrolled'}`}>
        {showBack && (
          <IconButton
            icon={faChevronLeft}
            onClick={() => {
              appViewStore.popPage();
            }}
          />
        )}
        <div className={`widget-header-sub-title`}>
          {configStore.translateText(appViewStore.getCurrentPage())}
        </div>
        <IconButton
          className="widget-header-app-close"
          icon={faXmark}
          onClick={() => {
            closeWidgetAndReset();
          }}
        />
      </div>
    );
  };

  const renderHeader = () => {
    if (routerStore.currentPage === "menu") {
      return renderMainHeader();
    }
    if (routerStore.currentPage === "flow") {
      return renderFormSubHeader();
    }
    if (routerStore.currentPage === "conversations") {
      return renderConversationsSubHeader();
    }
    if (routerStore.currentPage === "conversation" || routerStore.currentPage === "conversationloading") {
      if (ticketStore.currentTicket?.type === "BUG") {
        return renderConversationSubHeaderTicket();
      }
      return renderConversationSubHeader();
    }
    if (routerStore.currentPage === "news") {
      return renderNewsSubHeader();
    }
    if (routerStore.currentPage === "appview") {
      if (appViewStore.extendedView) {
        return renderAppExtendedViewHeader();
      }
      return renderAppViewHeader();
    }
    return null;
  };

  return (
    <div
      className={`widget-header ${scrolled && "widget-header--scrolled"} ${routerStore.currentPage === "menu" &&
        !routerStore.firstAnimation &&
        "widget-header--bg"
        } ${routerStore.currentPage === "menu" &&
        routerStore.firstAnimation &&
        "widget-header--bgfirst"
        } ${routerStore.currentPage === "conversation" && ticketStore.currentTicket?.type === "BUG" && 'widget-header--bglarge'}`}
    >
      <div
        className={`widget-header--shadow ${config.disableBGFade && "widget-header--nofade"
          } ${config.disableBGGradient && "widget-header--nogradient"}`}
      />
      {renderHeader()}
    </div>
  );
}

export default WidgetHeader;
