import { faChevronRight, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as CaretUpIcon } from "../../Assets/caret-up.svg";
import { useFeatureRequestStore } from "../../Stores/FeatureRequests";
import { useFormStore } from '../../Stores/Form';
import "./FeatureRequestSuggestions.scss";
import { useConfigStore } from "../../Stores/Config";

export const suggestionTitle = "We found existing feature requests that might align with your idea. Upvote the ones you like or go ahead and submit your own idea.";

function FeatureRequestSuggestions() {
    const formStore = useFormStore();
    const featureRequestStore = useFeatureRequestStore();

    return (
        <div className='form-duplicates'>
            {formStore.duplicates?.map((item: any, index: number) => {
                const upvotes = item.upvotesCount ?? 0;
                return (<div key={`duplicate-${index}`} className='form-duplicate' onClick={() => {
                    featureRequestStore.openFeatureRequest(item.shareToken);
                }}>
                    <div className='form-duplicate__upvotes'>
                        <CaretUpIcon />
                        {upvotes > 99 ? "99+" : upvotes}
                    </div>
                    <div className='form-duplicate__title'>
                        {item.title ?? "--"}
                    </div>
                    <FontAwesomeIcon icon={faChevronRight} />
                </div>);
            })}
            <div className="duplicate-line" />
            <div key={`duplicate-submitown`} className='form-duplicate' onClick={() => {
                formStore.sendForm(true);
            }}>
                <div className='form-duplicate__submiticon'>
                    <FontAwesomeIcon icon={faPlus} />
                </div>
                <div className='form-duplicate__title'>
                    {useConfigStore.getState().translateText("kaiSubmitIdea")}
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </div>
        </div>);
}

export default FeatureRequestSuggestions;
