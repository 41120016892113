import create from "zustand";
import { useRouterStore } from "./Router";
import { useSessionStore } from "./Session";
import { useConfigStore } from "./Config";

export type Article = {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  sentAt: Date;
  coverImageUrl: string;
  subject: string;
  message: any;
  sender: {
    firstName?: string;
    lastName?: string;
    id?: string;
    profileImageUrl?: string;
  };
};

interface NewsState {
  latestArticle?: Article;
  currentArticle?: Article;
  currentOffset: number;
  articles: Article[];
  loadingNews: boolean;
  loadMoreNews: () => void;
  loadNews: (loadMore?: boolean) => void;
  loadArticle: (id: string) => void;
}

export const useNewsStore = create<NewsState>()((set, get) => ({
  latestArticle: undefined,
  currentArticle: undefined,
  currentOffset: 0,
  articles: [],
  loadingNews: false,
  loadArticle: (id: string) => {
    const sessionStore = useSessionStore.getState();
    set({
      currentArticle: undefined,
    });

    var xhr = new XMLHttpRequest();
    xhr.open("GET", sessionStore.apiUrl + "/outbound/news/shared/" + id + "?lang=" + useConfigStore.getState().getCurrentLanguage());
    if (sessionStore.session) {
      xhr.setRequestHeader("Api-Token", sessionStore.sdkKey);
      xhr.setRequestHeader("Gleap-Id", sessionStore.session.gleapId ?? "");
      xhr.setRequestHeader("Gleap-Hash", sessionStore.session.gleapHash ?? "");
    }
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200 && xhr.responseText) {
        try {
          const data = JSON.parse(xhr.responseText);
          if (data) {
            set({
              currentArticle: data,
            });
          } else {
            useRouterStore.getState().setPage("");
          }
        } catch (exp) {
          useRouterStore.getState().setPage("");
        }
      }
    };

    xhr.send();
  },
  loadMoreNews() {
    get().loadNews(true);
  },
  loadNews(loadMore = false) {
    const sessionStore = useSessionStore.getState();
    var offset = get().currentOffset;

    if (!loadMore) {
      offset = 0;
      set({
        articles: [],
        loadingNews: true,
      });
    }

    var xhr = new XMLHttpRequest();
    xhr.open(
      "GET",
      sessionStore.apiUrl + "/outbound/news/shared?lang=" + useConfigStore.getState().getCurrentLanguage() + "&offset=" + offset
    );
    if (sessionStore.session) {
      xhr.setRequestHeader("Api-Token", sessionStore.sdkKey);
      xhr.setRequestHeader("Gleap-Id", sessionStore.session.gleapId ?? "");
      xhr.setRequestHeader("Gleap-Hash", sessionStore.session.gleapHash ?? "");
    }
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200 && xhr.responseText) {
        try {
          const data = JSON.parse(xhr.responseText);
          if (data) {
            set({
              articles: offset === 0 ? data : [...get().articles, ...data],
              currentOffset: offset + data.length,
              loadingNews: false,
            });

            if (offset === 0) {
              set({
                latestArticle: data.length > 0 ? data[0] : undefined,
              });
            }
          }
        } catch (exp) { }
      }
    };

    xhr.send();
  },
}));
