import moment from 'moment';
import { useTicketStore } from '../../Stores/Ticket';
import ChatAvatar from '../ChatAvatar/ChatAvatar';
import "./BotActionChatMessage.scss";
import { useConfigStore } from '../../Stores/Config';

function BotActionChatMessage({ children, date, hideAvatar = false }: { children: any, date?: Date, hideAvatar?: boolean }) {
    const ticketStore = useTicketStore();

    return (<div className={`bot-action-chat-message-container ${hideAvatar && "bot-action-chat-message-container--hide-avatar"}`}>
        <ChatAvatar />
        <div className='bot-action-chat-message'>
            <div className='bot-action-chat-message-inner'>
                {children}
            </div>
            <div className="message-date">
                {`${useConfigStore().getBotName()} · `}
                {moment(date ?? ticketStore?.currentBotAction?.lastActionSent ?? new Date()).fromNow()}
            </div>
        </div>
    </div>);
}

export default BotActionChatMessage;